<template>
  <v-container class="py-0 px-0">
    <iframe ref="iframe" src="https://open-lat.inja777.com/mobile/nowopen/STPK" frameborder="0" scolling="yes"
      :style="{ width: width, height: height }" allowfullscreen>
    </iframe>
  </v-container>
</template>

<script>
import Cookie from "js-cookie";
import { ref } from 'vue'

export default {
  name: "LotteryAreaWg",
  data() {
    return {
      width: document.documentElement.clientWidth + 'px',
      height: document.documentElement.clientHeight + 'px',
    };
  },
  mounted() {
    const token = Cookie.get('auth')
    const tokenParts = token.split('.')
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    this.width = document.documentElement.clientWidth + 'px'
    this.height = document.documentElement.clientHeight + 'px'
    console.log(this.width)
    console.log(this.height)

    //setInterval(() => {
    //  window.location.reload();
    //}, 1000 * 200)

  },
};
</script>
